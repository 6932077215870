<template>
  <v-container>
    <base-material-card
      id="plans-view"
      :title="$t('list', { name: $tc('customer', 2) })"
      icon="mdi-account"
    >
      <v-row>
        <v-btn
          color="primary"
          @click.native="
            $router.push({
              name: 'UserSettings',
              params: { type: 'myplan' },
            })
          "
          style="
            position: absolute;
            height: 30px;
            left: 15px;
            top: 30px;
            z-index: 5;
          "
          outlined
          v-if="!$vuetify.breakpoint.xsOnly"
        >
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("back") }}
        </v-btn>
      </v-row>
      <br />
      <v-row>
        <v-col cols="12" md="4" v-for="(plan, i) in plans" :key="i">
          <v-card
            class="plan_info"
            style="border: 1px solid white; min-height: 100%"
            v-bind:style="[
              {
                'border-color': plan.color,
                //'background-image': `url(${require('@/assets/plans-bg.png')})`,
              },
            ]"
            v-bind:class="[
              {
                selected_plan: plan.id === $store.state.auth.user.plan_id,
                bronze_card: plan.id === 1 || plan.id === 4,
                silver_card: plan.id === 2 || plan.id === 5,
                gold: plan.id === 3 || plan.id === 6,
              },
            ]"
          >
            <div
              style="
                position: absolute;
                z-index: 0 !important;
                opacity: 0.2;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
              "
              v-bind:style="[
                {
                  'background-image': `url(${require('@/assets/plans-bg.png')})`,
                },
              ]"
            ></div>
            <v-card-title class="text-center my-5">
              <h2
                class="mx-auto"
                v-bind:style="[
                  {
                    color: plan.color,
                  },
                ]"
              >
                {{ plan.name }}
              </h2>
            </v-card-title>
            <v-card-text>
              <p
                v-if="plan.id === 1 || plan.id === 4"
                class="text-center"
                style="min-height: 50px"
              >
                {{ $t("plans.text.bronze") }}
              </p>
              <p
                v-else-if="plan.id === 2 || plan.id === 5"
                class="text-center"
                style="min-height: 58px"
              >
                {{ $t("plans.text.silver") }}
              </p>
              <p v-else class="text-center" style="min-height: 50px">
                {{ $t("plans.text.gold") }}
              </p>
              <h2 class="text-center primary--text">
                {{ $n(plan.price, "currency") }}<sub>/{{ $t("month") }}</sub>
              </h2>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                style="height: 35px !important; width: 150px"
                elevation="0"
                outlined
                :disabled="plan.id === $store.state.auth.user.plan_id"
                @click="changePlan(plan.id)"
                >{{ $t("my_plan.contract") }}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
            <v-list
              flat
              v-if="plan.id === 1 || plan.id === 4"
              style="width: fit-content; margin: auto"
            >
              <template
                v-for="(key, j) in $t(
                  $store.getters['auth/isStudio']
                    ? 'plans.ticks.studio.bronze'
                    : 'plans.ticks.tattooer.bronze'
                )"
              >
                <v-list-item :key="`${i}-${j}`" style="min-height: unset">
                  <v-list-item-icon class="my-0 mr-2">
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title v-html="key"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <v-list
              flat
              v-else-if="plan.id === 2 || plan.id === 5"
              style="width: fit-content; margin: auto"
            >
              <template
                v-for="(key, j) in $t(
                  $store.getters['auth/isStudio']
                    ? 'plans.ticks.studio.silver'
                    : 'plans.ticks.tattooer.silver'
                )"
              >
                <v-list-item :key="`${i}-${j}`" style="min-height: unset">
                  <v-list-item-icon class="my-0 mr-2">
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title v-html="key"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <v-list flat v-else style="width: fit-content; margin: auto">
              <template
                v-for="(key, j) in $t(
                  $store.getters['auth/isStudio']
                    ? 'plans.ticks.studio.gold'
                    : 'plans.ticks.tattooer.gold'
                )"
              >
                <v-list-item :key="`${i}-${j}`" style="min-height: unset">
                  <v-list-item-icon class="my-0 mr-2">
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title v-html="key"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  mounted() {
    this.fetchUserPlans();
  },
  data() {
    return {
      plans: [],
    };
  },
  methods: {
    keys(plan) {
      return Object.keys(plan).filter((key) => {
        switch (key) {
          case "name":
          case "price":
          case "days_limit":
          case "stripe_product_id":
          case "stripe_price_id":
          case "role_id":
          case "id":
          case "default":
          case "role":
          case "color":
            return false;
          default:
            return true;
        }
      });
    },
    ...mapActions("plans", [
      "getUserPlans",
      "setUserPlan",
      "checkUserPaymentMethods",
    ]),
    fetchUserPlans() {
      this.getUserPlans().then((data) => (this.plans = data));
    },

    changePlan(id) {
      this.$confirm(this.$t("billing.changePlan"), "", "").then(() => {
        this.checkUserPaymentMethods().then((data) => {
          if (data.check) {
            this.setUserPlan(id);
          } else {
            this.$alert(this.$t("billing.no_pm"), "", "");
          }
        });
      });
    },
  },
};
</script>
<style lang="sass">
#plans-view
  .v-responsive__sizer
    display: none
  .v-card.plan_info
    .v-list
      background-color: transparent
    &.selected_plan
      border: 1px solid var(--v-primary-base)
  .plan_info
    *
      z-index: 9 !important
  .bronze_card
    b
      color: var(--v-bronze-base)
  .silver_card
    b
      color: var(--v-silver-base)
  .gold
    b
      color: var(--v-primary-base)
  .v-list-item__title
    font-weight: normal !important
    b
      font-weight: bold
</style>