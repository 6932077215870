var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('base-material-card',{attrs:{"id":"plans-view","title":_vm.$t('list', { name: _vm.$tc('customer', 2) }),"icon":"mdi-account"}},[_c('v-row',[(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{staticStyle:{"position":"absolute","height":"30px","left":"15px","top":"30px","z-index":"5"},attrs:{"color":"primary","outlined":""},nativeOn:{"click":function($event){return _vm.$router.push({
            name: 'UserSettings',
            params: { type: 'myplan' },
          })}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1):_vm._e()],1),_c('br'),_c('v-row',_vm._l((_vm.plans),function(plan,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"plan_info",class:[
            {
              selected_plan: plan.id === _vm.$store.state.auth.user.plan_id,
              bronze_card: plan.id === 1 || plan.id === 4,
              silver_card: plan.id === 2 || plan.id === 5,
              gold: plan.id === 3 || plan.id === 6,
            } ],staticStyle:{"border":"1px solid white","min-height":"100%"},style:([
            {
              'border-color': plan.color,
              //'background-image': `url(${require('@/assets/plans-bg.png')})`,
            } ])},[_c('div',{staticStyle:{"position":"absolute","z-index":"0 !important","opacity":"0.2","left":"0","top":"0","height":"100%","width":"100%","transition":"background 0.3s, border-radius 0.3s, opacity 0.3s"},style:([
              {
                'background-image': ("url(" + (require('@/assets/plans-bg.png')) + ")"),
              } ])}),_c('v-card-title',{staticClass:"text-center my-5"},[_c('h2',{staticClass:"mx-auto",style:([
                {
                  color: plan.color,
                } ])},[_vm._v(" "+_vm._s(plan.name)+" ")])]),_c('v-card-text',[(plan.id === 1 || plan.id === 4)?_c('p',{staticClass:"text-center",staticStyle:{"min-height":"50px"}},[_vm._v(" "+_vm._s(_vm.$t("plans.text.bronze"))+" ")]):(plan.id === 2 || plan.id === 5)?_c('p',{staticClass:"text-center",staticStyle:{"min-height":"58px"}},[_vm._v(" "+_vm._s(_vm.$t("plans.text.silver"))+" ")]):_c('p',{staticClass:"text-center",staticStyle:{"min-height":"50px"}},[_vm._v(" "+_vm._s(_vm.$t("plans.text.gold"))+" ")]),_c('h2',{staticClass:"text-center primary--text"},[_vm._v(" "+_vm._s(_vm.$n(plan.price, "currency"))),_c('sub',[_vm._v("/"+_vm._s(_vm.$t("month")))])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"height":"35px !important","width":"150px"},attrs:{"elevation":"0","outlined":"","disabled":plan.id === _vm.$store.state.auth.user.plan_id},on:{"click":function($event){return _vm.changePlan(plan.id)}}},[_vm._v(_vm._s(_vm.$t("my_plan.contract")))]),_c('v-spacer')],1),(plan.id === 1 || plan.id === 4)?_c('v-list',{staticStyle:{"width":"fit-content","margin":"auto"},attrs:{"flat":""}},[_vm._l((_vm.$t(
                _vm.$store.getters['auth/isStudio']
                  ? 'plans.ticks.studio.bronze'
                  : 'plans.ticks.tattooer.bronze'
              )),function(key,j){return [_c('v-list-item',{key:(i + "-" + j),staticStyle:{"min-height":"unset"}},[_c('v-list-item-icon',{staticClass:"my-0 mr-2"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")])],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(key)}})],1)],1)]})],2):(plan.id === 2 || plan.id === 5)?_c('v-list',{staticStyle:{"width":"fit-content","margin":"auto"},attrs:{"flat":""}},[_vm._l((_vm.$t(
                _vm.$store.getters['auth/isStudio']
                  ? 'plans.ticks.studio.silver'
                  : 'plans.ticks.tattooer.silver'
              )),function(key,j){return [_c('v-list-item',{key:(i + "-" + j),staticStyle:{"min-height":"unset"}},[_c('v-list-item-icon',{staticClass:"my-0 mr-2"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")])],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(key)}})],1)],1)]})],2):_c('v-list',{staticStyle:{"width":"fit-content","margin":"auto"},attrs:{"flat":""}},[_vm._l((_vm.$t(
                _vm.$store.getters['auth/isStudio']
                  ? 'plans.ticks.studio.gold'
                  : 'plans.ticks.tattooer.gold'
              )),function(key,j){return [_c('v-list-item',{key:(i + "-" + j),staticStyle:{"min-height":"unset"}},[_c('v-list-item-icon',{staticClass:"my-0 mr-2"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")])],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(key)}})],1)],1)]})],2)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }